var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Histórico de Vendas")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.listaItens,"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('BrToolBar',{attrs:{"configFilter":{ listaSelect: _vm.item, getItens: _vm.getItens, jsonData: false },"labelTextField":'Busca por cod:'},on:{"search":_vm.buscar},scopedSlots:_vm._u([{key:"btnSelect",fn:function(){return [_c('FiltroRangeDate',{attrs:{"value":_vm.rangeDate},on:{"input":_vm.filtrar}})]},proxy:true}])})]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.created))+" ")]}},{key:"item.webUserInfo",fn:function(ref){
var item = ref.item;
return [(item.webUserInfo !== null)?_c('v-btn',{staticClass:"table-btn",attrs:{"text":""},on:{"click":function($event){return _vm.opemModalDadosUsuario(item.webUserInfo.entityId)}}},[_vm._v(" "+_vm._s(item.webUserInfo !== null ? item.webUserInfo.entity.jsonData.fullName : "")+" ")]):_vm._e()]}},{key:"item.sellerInfo",fn:function(ref){
var item = ref.item;
return [(item.sellerInfo !== null && item.sellerInfo !== undefined)?_c('v-btn',{staticClass:"table-btn",attrs:{"text":""},on:{"click":function($event){return _vm.openInfoModal(item, 'seller')}}},[_vm._v(" "+_vm._s(item.sellerInfo !== null ? item.sellerInfo.entity.jsonData.name : "")+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(_vm._s(_vm.textStatus(item.status)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.permiteAcao(_vm.$route, 'edit') && (item.status !== 'E' && item.status !== 'C'))?_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","tile":"","outlined":"","color":_vm.getColor(item.status),"loading":_vm.loadingBtnsListagem},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" Cancelar ")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}])})],1)],1),_c('v-btn',{staticClass:"mx-2 fab-cadastrar-usuario",attrs:{"fab":"","color":"primary"},on:{"click":_vm.cadastrar}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1),_c('InfoModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }