<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Histórico de Vendas</h1>

      <div class="box-table">
        <v-skeleton-loader
            :loading="loadingTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 

            <template v-slot:top>
              <BrToolBar 
                @search="buscar"
                :configFilter="{ listaSelect: item, getItens: getItens, jsonData: false }"
                :labelTextField="'Busca por cod:'"
              >

                <template v-slot:btnSelect>
                  <FiltroRangeDate  @input="filtrar" :value="rangeDate" />
                </template>

              </BrToolBar>
            </template>

            <template v-slot:item.value="{ item }">
              {{ item.value | currency }}
            </template>

            <template v-slot:item.created="{ item }">
              {{ item.created | datas }}
            </template>

            <template v-slot:item.webUserInfo="{ item }">
               <v-btn
               v-if="item.webUserInfo !== null"
               @click="opemModalDadosUsuario(item.webUserInfo.entityId)"
               text
               class="table-btn">
                {{item.webUserInfo !== null ? item.webUserInfo.entity.jsonData.fullName : "" }}
              </v-btn>
            </template>

            <template v-slot:item.sellerInfo="{ item }">
               <v-btn
               v-if="item.sellerInfo !== null && item.sellerInfo !== undefined"
               @click="openInfoModal(item, 'seller')"
               text
               class="table-btn">
                {{item.sellerInfo !== null ? item.sellerInfo.entity.jsonData.name : ""}}
              </v-btn>
            </template>

            <template v-slot:item.status="{ item }">
               <v-chip :color="getColor(item.status)" dark>{{ textStatus(item.status) }}</v-chip>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'edit') && (item.status !== 'E' && item.status !== 'C')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="getColor(item.status)"
                class="mr-2"
                :loading="loadingBtnsListagem"
              >
                Cancelar
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
    <InfoModal/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
import listagemTables from '@/views/mixins/listagemTables'
import Events from '@/core/service/events'
// import moment from 'moment'

const namespaceStore = 'vendasCreditos'

export default {
  name: 'Vendascreditos',
  components: {
    BrToolBar: () => import('./components/toolbar'),
    InfoModal: () => import('@/views/components/modalDadosEntidades'),
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate')
  },
  mixins: [listagemTables],
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'COD', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'created' },
      { align: 'start', class: 'table-header', text: 'Usuario Web', value: 'webUserInfo' },
      { align: 'start', class: 'table-header', text: 'Vendedor', value: 'sellerInfo' },
      { align: 'start', class: 'table-header', text: 'Status', value: 'status' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],

    rangeDate: {
      start: '',
      end: ''
    },

    loadingBtnsListagem: false

    // itemsTypesFilters: [
    //   { text: 'Data de cadastro', value:"created" }
    // ]
  }),
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens', 'item']),
  },

  methods: {
    ...mapActions(namespaceStore, ['getItens', 'buscarItem', 'deletarItem', 'getItem', 'editarItem', 'clearItens']),


    buscar(val) {
      this.loadingPag = true
      this.textSearch = val

      this.objCampoBusca['_filter'] = { cod_ilike: `%${val.toUpperCase()}%` }
      this
        .getItens({ _filter: { cod_ilike: `%${val.toUpperCase()}%` } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },
    init() {
      this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    textStatus (status) {
      switch(status) {
        case ('N'):
          return 'Novo'
        case ('A'):
          return 'Ativado'
        case ('C'):
          return 'Cancelado'
        case ('E'):
          return 'Expirado'
      }
    },

    getColor(status) {
      switch(status) {
        case ('N'):
          return this.variables.colorPrimary
        case ('A'):
          return this.variables.colorSecondary
        case ('C'):
          return this.variables.colorError
        case ('E'):
          return this.variables.colorError
      }
    },

    editItem(item) {
      if (item.status !== 'N') {
        const nameUserWeb = item.webUserInfo ? item.webUserInfo.entity.jsonData.fullName : ''
         this.$swal({
          icon: 'warning',
          // text: `Tem certeza que deseja cancelar essa venda de crédito, no valor de ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.value)}`,
          text: `Atenção, o valor de ${currency(item.value)} será descontado da conta do cliente: ${nameUserWeb}, certeza que deseja cancelar?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.value) {
            this.loadingBtnsListagem = true
            this.editarItem({ id: item.id }).then(() => {
              this.loadingPag = true
              this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  order: { id: 'desc' } })
                .finally(() => this.loadingPag = false)
              successSnackbar('Crédito cancelado com sucesso!')
            }).catch(err => {
              errorSnackbar(err.error)
            })
            .finally(() => this.loadingBtnsListagem = false)
          }
        })

        return false
      }


      this.$swal({
        icon: 'warning',
        // text: `Tem certeza que deseja cancelar essa venda de crédito, no valor de ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.value)}`,
        text: `Tem certeza que deseja cancelar essa venda de crédito, no valor de ${currency(item.value)}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.loadingBtnsListagem = true
          this.editarItem({ id: item.id }).then(() => {
            this.loadingPag = true
            this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  order: { id: 'desc' } })
              .finally(() => this.loadingPag = false)
            successSnackbar('Crédito cancelado com sucesso!')
          }).catch(err => {
            errorSnackbar(err)
          })
          .finally(() => this.loadingBtnsListagem = false)
        }
      })
    },
    openInfoModal (item, type) {
      Events.$emit('entidades::modalDados', {item, type})
    },

    opemModalDadosUsuario (e) {
      Events.$emit('cadastro::openModalCadastro', { id: e })
    },

    filtrar (e) {
      if (e.start !== '' && e.end !== '') {
        this.loadingPag = true
        let _addlFilter = {}

        _addlFilter[`${e.selectFilter}_>=`] =  e.start + ' 00:00:00'
        _addlFilter[`${e.selectFilter}_<=`] =  e.end + ' 23:59:59'

         this.getItens({ _addlFilter })
        .finally(() => this.loadingPag = false)
      } else {
        this.loadingPag = true
        this.getItens({ page: this.page, pageSize: this.$store.getters.ItensPaginacao,  order: { id: 'desc' } })
             .finally(() => this.loadingPag = false)
      }
      this.rangeDate = {
        start: e.start,
        end: e.end
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    .table-btn {
      font-weight: normal;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
</style>